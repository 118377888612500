.location-maps-section{
  position: relative;
  width: 100%;
  overflow: hidden;

  .col-maps{
    padding-top: 10px;
    padding-bottom: 10px;

    .box-content{
      .col-info{
        padding-top: 22px;
        padding-bottom: 22px;
        line-height: 1.1;
        font-size: 0.95rem;
        text-align: justify;

        .box-btns{
          position: relative;
          display: block;
          padding-bottom: 25px;

          .btn-st1{
            padding: 0.45rem 1.20rem 0.40rem 1.20rem;
            margin: 3px 3px;
            color: #ffffff !important;
            font-size: 0.75rem;
            font-weight: 500;
            border-radius: 16px;
            background-color: $purple;

            &:first-child{
              margin-left: 0;
            }
            &:last-child{
              margin-right: 0;
            }

            &.active{
              background-color: #ad55d2 !important;
            }

            &:hover{
              background-color: $purple2;
            }
          }
        }

        .title{
          margin-bottom: 6px;
          color: $purple;
          font-size: 1.2rem;
          font-weight: 600;
        }

        .table{
          margin-top: 30px;
          margin-bottom: 20px;
          font-size: 0.90rem;
          line-height: 1.25;

          th{
            color: $purple;
            font-weight: 600;
          }
        }

        .distance{
          font-size: 1.00rem;
          font-weight: 500;
        }
      }

      .col-map{
        iframe{
          border: 0;
          width: 62.7vw;
          height: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 991px){
    .col-maps{
      padding-top: 0;
      padding-bottom: 15px;

      .box-content{
        .col-info{
          padding-top: 0;

          .box-btns{
            padding-bottom: 15px;
          }
        }

        .col-map{
          iframe{
            width: 100%;
            height: 250px;
          }
        }
      }
    }
  }
}

#location-maps-page{
  padding-bottom: 25px;

  .location-maps-section{
    .col-maps{
      .box-content{
        .col-map{
          iframe{
            min-height: 320px;
          }
        }
      }
    }
  }
}
