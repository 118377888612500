// Fonts
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

.f-w-300{
  font-weight: 300 !important;
}
.f-w-400{
  font-weight: 400 !important;
}
.f-w-500{
  font-weight: 500 !important;
}
.f-w-600{
  font-weight: 600 !important;
}
.f-w-700{
  font-weight: 700 !important;
}

.txt-purple{
  color: $purple !important;
}
