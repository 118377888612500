#header{
	position: relative;
	width: 100%;
	height: 87px;
	display: flex;

	.bg-default {
		background-color: #fff;
	}

	.header-content{
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 80;
	}

	@media screen and (max-width: 1199px){
		height: 77px;
	}
	@media screen and (max-width: 991px){
		height: 59px;
	}

	.navbar{
		border-bottom: 1px solid rgba(0,0,0,0.12);

		.navbar-brand{
			.logo{
				max-height: 60px;
			}
		}

		.navbar-toggler{
			border: 0;
			font-size: 1.9rem;
			color: $purple;

			&:hover{
				color: $purple2;
			}

			.navbar-toggler-icon{
				display: none;
			}
		}

		.header-navigation{
			.header-menu{
				.item-inicio{}
				.simple-item{
					position: relative;
					padding-left: 0.1rem;
					padding-right: 0.1rem;

					.nav-link{
						position: relative;
						color: #3e3e3e;
						font-size: 0.95rem;
						font-weight: 500;

						&:before{
							content: " ";
							position: absolute;
							bottom: -10px;
							left: 0;
							width: 100%;
							border-bottom: 2px solid $purple;
							visibility: hidden;
							opacity: 0;
							@include transition(50ms);
						}
					}
				}

				.jes-green{
					.nav-link{
						color: $green;

						&:after{
							border-bottom-color: $green;
						}

						&:hover{
							color: #256215 !important;
						}
					}
				}

				.cart-item{
					position: relative;

					.nav-link{
						&:before{
							content: " ";
							position: relative;
							display: inline-block;
							top: 4px;
							margin-right: 5px;
							width: 23px;
							height: 23px;
							background-position: center;
							background-repeat: no-repeat;
							background-size: contain;
							background-image: url('../images/shared/cart-icon.svg');
							// background-color: red;
						}

						.num{
							display: inline-block;
							padding: 3px 1px 3px 1px;
							min-width: 18px;
							height: 18px;
							color: #fff;
							line-height: 1;
							font-style: normal;
							text-align: center;
							font-size: 0.7rem;
							font-weight: 400;
							border-radius: 6px;
							background-color: $purple;
						}
					}

				}
			}

			.search-form{
				position: relative;
				@include placeholder(#979797, 400, italic, 0.875rem);

				.form-control{
					padding: 0.4rem 0.5rem 0.4rem 44px;
					width: 250px;
					height: auto !important;
					color: #7F7F80;
					font-weight: 300;
					border-radius: 30px;
					border-color: #f0f0f0;
					box-shadow: 0 0 4px rgba(0,0,0,0.15);
					// background-color: #f0f0f0;
				}

				.btn-search{
					position: absolute;
					top: 0;
					left: 12px;
					width: 26px;
					height: 100%;
					font-size: 0.875rem;
					border: 0 !important;
					background-image: url('../images/shared/search-icon.svg');
					background-position: center;
					background-repeat: no-repeat;
					background-size: 73%;
					background-color: transparent;
					z-index: 4;
				}
			}

			.extra-menu{
				.user-icon{
					color: #9b9b9b;
					font-size: 1.4rem;
				}

				.dropdown-menu{
					.dropdown-item{
						color: #9d9d9d;
						font-size: 0.9rem;

						&:focus, &:active{
							color: #000 !important;
							background-color: transparent !important;
						}
					}
				}
			}
		}

		@media screen and (min-width: 992px){
			.header-navigation{
				.header-menu{
					.simple-item{
						.nav-link{
							padding-left: 0.6rem;
							padding-right: 0.6rem;

							&:hover{
								color: #000;

								&:before{
									visibility: visible;
									opacity: 1;
								}
							}
						}
					}
				}
				.extra-menu{
					.dropdown-menu{
						padding: 0.3rem 0;
						border-radius: 0;
						margin-top: 13px;
						// border-top: 0;

						.dropdown-item{
							padding: 0.2rem 1.5rem;
							font-size: 0.85rem;
							font-weight: 400;
						}
					}
				}
			}

			@media screen and (max-width: 1245px){
				.search-form {
					display: none !important;
				}
			}

			@media screen and (max-width: 1199px){
				.navbar-brand{
					.logo{
						max-height: 50px;
					}
				}

				.header-navigation{
					.header-menu{
						.nav-item{
							.nav-link{
								padding-left: 0.2rem;
								padding-right: 0.2rem;
								font-size: 0.90rem;
							}
						}
					}
					.search-form{
						.form-control{
							width: 180px !important;
						}
					}
				}
			}
		}
		@media screen and (min-width: 1200px) and (max-width: 1310px){
			.header-navigation{
				.header-menu{
					.simple-item{
						.nav-link{
							padding-left: 0.45rem;
							padding-right: 0.45rem;
							font-size: 0.90rem;
						}
					}
				}
			}
		}
		@media screen and (min-width: 1241px) and (max-width: 1430px){
			.header-menu{
				.item-inicio{
					display: none !important;
				}
			}
		}
		@media screen and (min-width: 1400px){
			.header-navigation{
				padding-left: 40px;
			}
		}
		@media screen and (max-width: 991px){
			.navbar-brand{
				padding-top: 1px;
				padding-bottom: 1px;

				.logo{
					max-height: 40px;
				}
			}

			.header-navigation{
				.search-form{
					margin-top: 5px;
					margin-bottom: 5px;

					.form-inline{
						width: 100%;
					}

					.form-control{
						width: 100%;
						border-radius: 3px;
					}
				}
			}
		}

	}
}
