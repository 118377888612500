body,html{
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
	color: #3e3e3e;
}

p,h1,h2,h3,h4,h5,h6{
	margin-bottom: 0px;
}

#app{
	>.page{
		min-height: 64vh;
	}
}

// Container
.oversized-container{
	@media screen and (min-width: 992px){
		max-width: 98%;
	}
	@media screen and (min-width: 1630px){
		max-width: 1590px;
	}
}

// Backgrounds
.placed-backg{
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

// Remove outline
select:focus, select:active, select:hover, button:focus, button:active, button:hover,
input:focus, input:active, input:hover, textarea:focus, textarea:active, textarea:hover{
  box-shadow: none !important;
  outline: none !important;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow:none !important;
}

// Messenger chat icon
.fl-ic-messenger{
	position: fixed;
	@include flex-center-xy();
	background-color: #0a7cff;
	border-radius: 50%;
	bottom: 90px;
	color: #fff !important;
	cursor: pointer;
	font-size: 32px;
	height: 60px;
	padding-bottom: 2px;
	right: 24px;
	text-align: center;
	text-decoration: none !important;
	width: 60px;
	z-index: 88;
}
