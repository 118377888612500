#hotels-v2-page{
  padding-bottom: 25px;

  .location-hotel-section{
    position: relative;
    width: 100%;
    overflow: hidden;

    .col-maps{
      padding-top: 10px;
      padding-bottom: 10px;

      .box-content{
        margin-bottom: 1.3rem;

        &:last-child{
          margin-bottom: 0 !important;
        }

        .col-info{
          padding-top: 22px;
          padding-bottom: 22px;
          line-height: 1.1;
          font-size: 0.95rem;

          a{
            color: #3e3e3e;
          }

          .title{
            margin-bottom: 6px;
            color: $purple;
            font-size: 1.5rem;
            font-weight: 700;
          }

          .subtitle{
            font-size: 1.05rem;
            font-weight: 700;
          }

          .table{
            margin-top: 30px;
            margin-bottom: 20px;
            font-size: 0.90rem;
            line-height: 1.25;

            th{
              color: $purple;
              font-weight: 600;
            }
          }

          .distance{
            font-size: 1.00rem;
            font-weight: 500;
          }
        }

        .col-map{
          iframe{
            border: 0;
            width: 62.7vw;
            height: 300px;
          }
        }
      }
    }

    @media screen and (max-width: 991px){
      .col-maps{
        padding-top: 0;
        padding-bottom: 15px;

        .box-content{
          .col-info{
            padding-top: 0;
          }

          .col-map{
            iframe{
              width: 100%;
              height: 250px;
            }
          }
        }
      }
    }
  }

}
