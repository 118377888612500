#home-page{

	// == Banners ==
	.banners-section{
		.swiper-s2{}
	}
	// == ==

	// == Acerca ==
	.about-section{
		.col-media{
			text-align: right;

			.image{
				position: relative;
				float: right;
				width: 50vw;
				height: 100%;
				background-color: $soft-gray;
				border-right: 1px solid #979797;
				border-bottom: 1px solid #979797;
			}

			.video {
				position: relative;
				float: right;
				width: 50vw;
				height: 100%;
			}
		}

		.col-info{
			padding-top: 55px;
			padding-bottom: 55px;

			.box{
				padding-top: 20px;
				padding-bottom: 20px;
				align-items: center !important;

				.col-text{
					text-align: right;

					h5{
						font-size: 1.55rem;
						font-weight: 700;
						margin-bottom: 6px;
					}
				}

				.col-icon{
					flex: 0 0 70px;
					max-width: 70px;
					color: #9c9c9c;
					font-size: 2.5rem;
					text-align: center;
				}
			}
		}

		@media screen and (max-width: 991px){
			.col-media{
				padding-left: 0;
				padding-right: 0;
				height: 350px;
				order: 2;

				.image{
					width: 100%;
				}
			}

			.col-info{
				padding-top: 30px;
				padding-bottom: 15px;
			}
		}
	}
	// == ==

	// == Novedades ==
	.articles-section{
		padding-top: 30px;
		padding-bottom: 10px;

		.swiper{
			.swiper-slide{
				padding-bottom: 36px;

				.col-article-sample-1{
					.box-article{
						padding-bottom: 6px !important;
					}
				}
			}

			.swiper-pagination{
				.swiper-pagination-bullet{
					background-color: $purple;
				}
			}
		}
	}
	// == ==

	// == Mapas - Como llegar ==
	.location-maps-section{}
	// == ==

	// == Tiendas ==
	.stores-section{
		position: relative;
		width: 100%;
		padding-bottom: 30px;

		.col-products{

		}
	}
	// == ==
}
