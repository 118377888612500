#description-page{
  padding-bottom: 20px;

  .description-section{

    .col-gallery{
      .swiper-top{
        .swiper-wrapper{
          .swiper-slide{
            .image-container{
              display: inline-block;
              width: 100%;
              // max-height: 530px;

              .img{
                display: inline-block;
                text-align: center;
                width: 100%;

                img{
                  display: inline-block;
                  width: 100%;
                  max-height: inherit;
                }
              }
            }
          }
        }

        .swiper-pagination{
          bottom: 15px;

          .swiper-pagination-bullet{
            width: 11px;
            height: 11px;
            background-color: #ffffff;
            opacity: 1;
          }
          .swiper-pagination-bullet-active{
            background-color: $purple;
          }
        }

        .swiper-button-prev, .swiper-button-next{
          width: 30px;
          border-radius: 3px;
          box-shadow: 0 0 2px rgb(0, 0, 0, 0.30);
          background-color: #ffffff;

          &:after{
            color: $purple;
            font-size: 24px;
            font-weight: 700;
          }
        }
        .swiper-button-prev{
          left: 28px;
        }
        .swiper-button-next{
          right: 28px;
        }

        @media screen and (max-width: 575px){
          .swiper-button-prev{
            left: 16px;
          }
          .swiper-button-next{
            right: 16px;
          }
        }
      }

      .swiper-th{
        margin-top: 15px;

        .swiper-wrapper{
          .swiper-slide{
            opacity: 0.7;

            .image{
              display: inline-block;
              width: 100%;
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
              border-color: $soft-gray;
              cursor: pointer;
            }
          }

          .swiper-slide-active{
            opacity: 1;

            .image{
              cursor: default;
            }
          }
        }
      }
    }

    .col-description{
      >.brand{
        margin-bottom: 5px;
        color: #959595;
        font-weight: 400;
      }

      .box-images{
        .col-logo{
          img{
            max-height: 90px;
          }
        }

        .col-loc{
          img{
            max-height: 150px;
          }
        }
      }

      >.name{
        color: #000000;
        font-size: 1.7rem;
        font-weight: 700;
      }
      .extra-breadcrumb{
        color: #464545;
        font-size: 0.90rem;
        line-height: 1.25;

        strong{
          color: #000000;
        }

        a{
          color: #464545;
        }
      }
      .box-categories{
        .col-label{}
        .col-categories{}

        @media screen and (min-width: 992px){
          .col-label{
            padding-right: 5px;
            flex: 0 0 96px;
            max-width: 96px;

            strong{
              position: relative;
              top: 5px;
            }
          }
          .col-categories{
            flex: 0 0 calc(100% - 96px);
            max-width: calc(100% - 96px);
            padding-left: 0;
          }
        }
      }
      .bubble{
        display: inline-block;
        padding: 4px 10px;
        margin: 0.15rem;
        color: #ffffff !important;
        font-size: 0.9rem;
        font-weight: 500;
        text-decoration: none !important;
        border-radius: 4px;
        background: rgb(86,187,61);
        // background: linear-gradient(231deg, rgba(86,187,61,1) 0%, rgba(149,191,44,1) 100%);

        @include transition(150ms);

        &:hover{
          opacity: 0.9;
        }
      }
      >.price{
        color: #1b1a1d;
        font-size: 2.2rem;
      }
      >.discount{
        color: #a6a3a3;
        text-decoration: line-through;

        span{
          font-size: 88%;
        }
      }

      .subtitle{
        color: #000000;
        font-size: 1rem;
        font-weight: 600;
      }

      .txt-description{
        margin-bottom: 10px;
        color: #464545;
        font-size: 0.9rem;
        line-height: 1.4;

        p{
          margin-bottom: 4px;

          &:last-child{
            margin-bottom: 0;
          }
        }
      }

      .box-options{
        position: relative;
        padding-top: 20px;
        padding-bottom: 15px;
        width: 100%;

        .col-opts{
          margin: 4px 5px;

          &:first-child{
            margin-left: 0;
          }
          &:last-child{
            margin-right: 0;
          }
        }

        .col-sizes{
          max-width: 115px;
        }
        .col-quantity{
          max-width: 135px;
        }
        .col-extra-selects{
          max-width: 210px;
        }
        .col-short{
          max-width: 90px;
        }
        .col-selects{
          .custom-select{
            border-color: #000000;
            font-size: 0.92rem;
          }
        }
      }

      .btn-submit{
        width: 210px;
        max-width: 100%;
        padding: 0.55rem 0.75rem;
        color: #fff;
        font-size: 0.85rem;
        font-weight: 600;
        border-color: $purple;
        border-radius: 0;
        background-color: $purple;

        &:hover{
          border-color: $purple2;
          background-color: $purple2;
        }

        &.btn-contact{
          background-color: #353434;
          border-color: #353434;

          &:hover{
            background-color: #444343;
            border-color: #444343;
          }
        }
      }

      .col-extra{
        position: relative;
        display: inline-block;

        h6{
          margin-bottom: 5px;
          font-size: 0.90rem;
        }

        .btn-q{
          position: relative;
          display: inline-block;
          margin: 1px 4px;
          color: #ffffff;
          border-radius: 2px;
          background: $purple;
          cursor: pointer;
          @include transition(250ms);

          span{
            display: flex;
            flex-wrap: wrap;
            width: 34px;
            flex: 0 0 34px;
            max-width: 34px;
            height: 34px;
            display: flex;
            -ms-flex-pack: center !important;
            justify-content: center !important;
            -ms-flex-align: center !important;
            align-items: center !important;
          }

          &:hover{
            background-color: $purple;
          }
        }

        .b-print{
          padding-left: 0;
          flex: 0 0 95px;
          max-width: 95px;
        }
      }

      @media screen and (max-width: 575px){
        .box-seller-information{
          margin-left: -15px;
          margin-right: -15px;
          margin-top: 15px;
          padding: 20px 17px 20px 17px;
          width: auto;
          border-top: 2px solid rgba(0,0,0,0.12);

          .col-info{
            max-width: calc(100% - 50px);
          }
          .col-links{
            display: none;
          }
        }

        >.name{
          font-size: 1.6rem;
        }
        >.price{
          font-size: 1.3rem;
        }
        .txt-description{
          font-size: 0.85rem;
          text-align: justify;
        }
        .btn-submit{
          padding: 0.7rem 0.75rem;
          width: 100%;
        }
      }
    }

  }

  // == Tiendas ==
	.stores-section{
		position: relative;
		width: 100%;
    padding-top: 30px;
		padding-bottom: 30px;

		.col-products{

		}
	}
	// == ==

}
