.sample-products-wrapper{
  margin-left: -10px;
  margin-right: -10px;

  .sample-product{
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 20px;
  }

  &.xxl-4e{
    .sample-product{
      @media screen and (min-width: 1500px){
        flex: 0 0 25%;
        max-width: 25%;
      }
    }
  }
  &.xxl-5e{
    .sample-product{
      @media screen and (min-width: 1400px){
        flex: 0 0 20%;
        max-width: 20%;
      }
    }
  }
}

.sample-product{
  &.shad-prod{
    .outlined-box{
      box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
    }
  }

  .outlined-box{
    cursor: pointer;
    @include transition(200ms);

    &:hover{
      text-decoration: none !important;
      opacity: 0.8;
      border-color: #797b7b;
    }
  }

  .outlined-box{
    display: inline-block;
    width: 100%;
    background-color: #ffffff;
    // border: 1px solid #E0E0E0;
    border: 16px;
    border-radius: 0;

    .note{
      position: absolute;
      padding: 3px 12px 3px 12px;
      top: 14px;
      left: 14px;
      color: #ffffff;
      font-size: 1.00rem;
      font-weight: 600;
      border-radius: 14px;
      background-color: $purple;
      z-index: 8;
    }

    .promo{
      position: absolute;
      padding: 4px 14px 5px 14px;
      top: 20px;
      right: 0;
      color: #ffffff;
      font-size: 0.80rem;
      font-weight: 500;
      text-align: center;
      background-color: #45a22e;
    }

    .box-image{
      position: relative;
      @extend .flex-center-xy;
      height: 100%;
      text-align: center;
      // border-bottom: 1px solid rgba(0,0,0,0.2);

      .img{
        display: inline-block;
        max-width: 100%;
        text-align: center;

        img{
          display: inline-block;
          max-width: 100%;
          max-height: 100%;
        }
      }
    }

    .box-shot-descr{
      display: block;
      // min-height: 147px;
      // overflow: auto;
      // overflow-x: hidden;
      padding: 15px 18px 10px 18px;
      text-align: center;

      .name{
        width: 100%;
        min-height: 48px;
        color: #3e3e3e;
        font-size: 1.2rem;
        font-weight: 700;
        // text-overflow: ellipsis;
        // overflow: hidden;
        // white-space: nowrap;
      }

      .descr{
        height: 35px;
        color: #939393;
        font-size: 0.85rem;
        font-weight: 300;
        line-height: 1.3;
        overflow: hidden;
      }
    }

    .p-button{
      margin-bottom: 25px;
      text-align: center;

      .btn-more{
        display: inline-block;
        padding: 6px 30px;
        width: 145px;
        max-width: 90%;
        color: #ffffff;
        font-size: 1.0rem;
        font-weight: 500;
        text-decoration: none !important;
        border-radius: 16px;
        background-color: $purple;
      }
    }
  }
}
