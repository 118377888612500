#toilets-page{
  position: relative;

  .text-section{
    padding-top: 9px;
    margin-bottom: 6px;
    font-size: 1.05rem;
    font-weight: 500;
    line-height: 1.3;
    text-align: center;

    .a-focus{
      color: $purple;
      font-weight: 700;
    }

    @media screen and (max-width: 575px){
      padding-bottom: 6px;
      font-size: 0.95rem;
      font-weight: 400;
      line-height: 1.1;
    }
  }

  .gallery-section{
    margin-bottom: 25px;

    .col-sketch{
      text-align: center;

      img{
        max-width: 90%;
      }
    }

    .col-toilets{}

    .col-photo{
      padding-top: 15px;
      padding-bottom: 15px;

      .box{
        position: relative;
        border: 1px solid rgba(0,0,0,0.2);
        cursor: pointer;

        &:hover{
          opacity: 0.8;
        }

        img{
          width: 100%;
        }
      }
    }

    @media screen and (max-width: 575px){
      .col-photo{
        &:nth-child(even){
          padding-left: 8px;
        }
        &:nth-child(odd){
          padding-right: 8px;
        }
      }
    }
  }

}
