.col-s-title{
  padding-top: 10px;
  padding-bottom: 10px;
  color: #3e3e3e;
  text-align: left;

  .title{
    font-size: 1.95rem;
    font-weight: 700;
  }
}
