.swiper-s1{
  .swiper-slide{
    padding: 5px;
  }

  .swiper-button-prev, .swiper-button-next{
    top: 38%;
    color: $purple;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background: #ffffff;
    opacity: 0.9;

    &:after{
      font-size: 22px;
      font-weight: 700;
    }

    &:hover{
      opacity: 1;
    }
  }

  .swiper-button-disabled{
    display: none !important;
  }
}

.swiper-s2{
  .swiper-slide{}

  .swiper-button-prev, .swiper-button-next{
    color: $purple;
    width: 44px;
    height: 60px;
    margin-top: -30px;
    border-radius: 4px;
    background-color: #ffffff;
    @include transition(250ms);

    &:after{
      font-size: 30px;
    }
  }
  .swiper-button-prev{
    left: 15px;
  }
  .swiper-button-next{
    right: 15px;
  }

  &:hover{
    .swiper-button-prev, .swiper-button-next{
      color: $purple2;
    }
  }

  @media screen and (max-width: 575px){
    .swiper-button-prev, .swiper-button-next{
      width: 34px;
      height: 48px;
      margin-top: -24px;
      opacity: 1 !important;

      &:after{
        font-size: 22px;
        font-weight: 700;
      }
    }

    .swiper-button-prev{
      left: 0;
      border-radius: 0 4px 4px 0;
    }
    .swiper-button-next{
      right: 0;
      border-radius: 4px 0 0 4px;
    }
  }
}
