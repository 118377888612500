#results-page{

  .products-section{
    position: relative;
    padding-bottom: 20px;
    min-height: 72vh;

    .category-title{
      text-align: center;
      font-size: 2.0rem;
      font-weight: 400;
    }

    .col-filters-mobile{
	    margin-bottom: 15px;
	    text-align: right;

	    @media screen and (min-width: 992px){
	      display: none;
	    }

	    .btn-filters{
	      display: inline-block;
	      color: $purple;
	      cursor: pointer;

	      i{
	        font-size: 0.9rem;
	      }

	      span{
	        margin-left: 6px;
	        font-size: 1.00rem;
	        font-weight: 600;
	      }
	    }

	    .bg-filter-mv{
	      position: fixed;
	      top: 0;
	      left: 0;
	      width: 100vw;
	      height: 100%;
	      background-color: rgba(0,0,0,0.05);
	      z-index: 55;
	    }
	  }

    .col-filters{
	    padding: 15px;
	    flex: 0 0 290px;
	    max-width: 290px;
	    min-height: 100px;
      box-shadow: 0 0 7px rgba(0,0,0,0.25);

	    ._title{
	      position: relative;
	      display: inline-block;
	      padding-bottom: 12px;
	      width: 100%;
	      font-size: 1.30rem;

	      span{
	        position: relative;
	        width: 100%;
	        color: $purple;
	        font-weight: 400;
	        padding-left: 5px;

	        &:before{
	          content: " ";
	          position: absolute;
	          bottom: -8px;
	          left: 0;
	          width: calc(100% - 5px);
	          border-bottom: 4px solid #ffffff;
	        }
	      }

	      .btn-hide{
	        position: absolute;
	        display: none;
	        top: -13px;
	        right: -15px;
	        width: 40px;
	        height: 44px;
	        color: #fff;
	        text-align: center;
	        background-color: $purple;
	        cursor: pointer;

	        i{
	          margin-top: 13px;
	        }
	      }
	    }

	    .box-filters{
	      position: relative;
	      display: inline-block;
	      width: 100%;
	      margin-bottom: 17px;

	      .filters-container{
	        position: relative;
	        display: inline-block;
	        width: 100%;
	      }

	      .btn-action{
	        display: inline-block;
	        width: 100%;
	        color: $purple;
	        font-weight: 500;
	        font-size: 1.18rem;
          text-decoration: none !important;

	        .sy{
	          display: inline-block;
	          width: 12px;
	          font-style: normal;
	        }
	      }

	      .box-filters-group{
	        position: relative;
	        display: inline-block;
	        width: 100%;
	        padding-left: 17px;
	        padding-right: 12px;
	        margin-top: 5px;

					&.form-group{
						margin-bottom: 0;
					}

	        .custom-checkbox{
	          display: inline-block;
	          padding-left: 2.00rem;
	          width: 100%;
	          margin-top: 2px;
	          margin-bottom: 2px;

	          .custom-control-label{
	            padding-top: 2px;
	            width: 100%;
	            color: #5a5c63;
	            font-weight: 300;
	            font-size: 0.92rem;
	            cursor: pointer;

	            &:before, &:after{
	              border-radius: 0;
	              width: 1.1rem;
	              height: 1.1rem;
	              top: 0.20rem;
	              left: -2.00rem;
	            }
	            &:before{
	              border: 1px solid #5a5c63;
	            }
	          }

	          .custom-control-input:checked ~ .custom-control-label::before{
	            background-color: $purple;
	          }
						.custom-control-input:checked ~ .custom-control-label::after{
	            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
	          }
	        }

	        .c-filter-select{
	          padding: 0.25rem 1.75rem 0.25rem 0.75rem;
	          width: 100%;
	          height: auto;
	          color: #5a5c63;
	          font-size: 0.85rem;
	          border: 1px solid #5a5c63;
	          border-radius: 0;
	          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23312164' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e");
	        }
	      }
	    }

	    @media screen and (min-width: 992px) and (max-width: 1199px){
	      flex: 0 0 240px;
	      max-width: 240px;

	      ._title{
	        font-size: 1.23rem;

	        span{
	          &:before{
	            bottom: -5px;
	            border-bottom-width: 3px;
	          }
	        }
	      }
	    }
	    @media screen and (max-width: 991px){
	      position: fixed;
	      padding-top: 13px;
	      padding-left: 15px;
	      padding-right: 15px;
	      top: 0;
	      left: 0;
	      width: 350px;
	      max-width: 100%;
	      height: 100%;
	      box-shadow: 0px 0px 3px rgba(0,0,0,0.25);
	      background-color: #ffffff;
	      z-index: 99;
	      @include transition(350ms);
	      @include cp-property(transform, translateX(-365px));

	      &.show-filters{
	        @include cp-property(transform, translateX(0));
	      }

	      ._title{
	        font-size: 1.10rem;

	        span{
	          &:before{
	            bottom: -6px;
	            border-bottom-width: 3px;
	          }
	        }

	        .btn-hide{
	          display: flex;
            font-size: 25px;
            padding-bottom: 2px;
	        }
	      }

	      .filters-container{
	        position: relative;
	        width: 100%;
	        height: calc(100% - 33px - 19px - 5px);
	        overflow: auto;
	        overflow-x: hidden;

	        .box-filters{

	          .btn-action{
	            font-size: 1.05rem;
	          }

	          .box-filters-group{
							.c-filter-select{
								width: 100%;
							}
	            .custom-checkbox{
	              .custom-control-label{
	                font-size: 0.87rem;
	              }
	            }
	          }
	        }
	      }
	    }
	  }

  }
}
