#services-page{

  .breadcrumb-banner-section{
    margin-bottom: 0;
  }

  .info-section{
    position: relative;
    width: 100%;
    overflow: hidden;

    .wr-swiper{
      .swiper-slide{
        background-color: #ffffff;
      }

      .swiper-button-prev, .swiper-button-next{
        color: $purple;
        @media screen and (min-width: 992px){
          top: 5%;
        }

        &:after{
          font-size: 30px;
          font-weight: 700;
        }


      }

      .swiper-pagination{
        .swiper-pagination-bullet{
          width: 16px;
          height: 16px;
          border: 2px solid $green;
          background-color: $purple;
        }
      }
    }

    .col-gallery{
      .swiper{
        .swiper-wrapper{
          .swiper-slide{
            .box{
              display: block;

              img{
                width: 50vw;
              }
            }
          }
        }

        .swiper-button-prev, .swiper-button-next{
          color: #ffffff;
        }

        .swiper-button-prev{
          left: 22px;
        }
        .swiper-button-next{
          right: 22px;
        }
      }
    }

    .col-info{
      padding-top: 16px;
      padding-bottom: 16px;

      .title{
        margin-bottom: 6px;
        font-size: 1.8rem;
        font-weight: 700;
      }

      .subtitle{
        color: $purple;
        font-size: 1.55rem;
        font-weight: 700;
      }

      .p-lg{
        font-size: 1.15rem;
      }

      a{
        color: $green;
      }

      .box-rest-info{
        margin-top: 5px;

        p, div{
          display: block;

          strong{
            color: $purple;
            font-weight: 700;
          }
        }
      }
    }

    &.g-right{
      .col-gallery{
        .content{
          position: relative;
          width: 50vw;
          background: #222;
          float: left;
        }
      }

      .col-info{
        text-align: left;
      }
    }
    &.g-left{
      .col-gallery{
        .content{
          position: relative;
          width: 50vw;
          background: $soft-gray;
          float: right;
        }
      }

      .col-info{
        text-align: right;
      }
    }

    @media screen and (max-width: 991px){
      &.g-left, &.g-right{
        margin-bottom: 30px;

        .col-gallery{
          order: 1 !important;

          .content{
            width: 100%;
          }
        }

        .col-info{
          order: 0 !important;
          text-align: center;
          padding-top: 15px;
          padding-bottom: 15px;
        }
      }
    }
  }

}
