.breadcrumb-banner-section{
  position: relative;
  margin-bottom: 15px;
  background: $purple;

  .container{
    @include flex-center-xy();
    padding-top: 15px;
    padding-bottom: 20px;
    color: #ffffff;
    height: 140px;

    >.box{
      position: relative;
      display: inline-block;
      width: 100%;

      .page-breadcrumb{
        font-size: 0.95rem;

        ul{
          display: inline-block;
          padding-left: 0;
          margin-bottom: 0;
          list-style-type: none;

          li{
            position: relative;
            display: inline-block;

            a{
              color: #fff;
            }

            &:after{
              content: "/";
              display: inline-block;
              margin-left: 6px;
              margin-right: 4px;
              font-size: 0.8rem;
            }

            &:last-child{
              font-weight: 600;

              &:after{
                content: "";
                display: none;
              }
            }
          }
        }
      }

      .page-name{
        display: inline-block;
        width: 100%;
        text-align: center;
        font-size: 2.1rem;
        font-weight: 600;

        @media screen and (min-width: 992px){
          position: relative;
          top: -10px;
        }
      }
    }
  }
}
