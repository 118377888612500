.breadcrumb-section{
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #575656;
  font-size: 0.85rem;

  ul{
    display: inline-block;
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;

    li{
      position: relative;
      display: inline-block;

      a{
        color: #97989b;
      }

      &:after{
        content: "/";
        display: inline-block;
        margin-left: 6px;
        margin-right: 4px;
        font-size: 0.8rem;
      }

      &:last-child{
        font-weight: 600;

        &:after{
          content: "";
          display: none;
        }
      }
    }
  }
}
