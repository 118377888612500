#sketch-page{
  .sketch-section{
    padding-top: 10px;
    padding-bottom: 20px;

    .zoom{
      padding: 20px;
      box-shadow: 0 0 17px rgba(0,0,0,0.10);
      cursor: zoom-in;

      &:after{
        display: none !important;
      }
    }

    .btn-download{
      display: inline-block;
      padding: 11px 15px;
      width: 100%;
      color: #ffffff;
      font-weight: 600;
      text-transform: uppercase;
      text-decoration: none !important;
      border-radius: 6px;
      background: $purple;

      &:hover{
        background-color: #481755;
      }
    }
  }
}
