#hotels-page{

  .hotels-section{
    margin-top: 25px;
    margin-bottom: 35px;

    .col-hotel{
      padding-top: 14px;
      padding-bottom: 14px;

      .box{
        position: relative;
        width: 100%;
        height: 555px;
        background-color: #333;

        .inside{
          position: absolute;
          @include flex-center-xy();
          padding: 20px;
          width: 100%;
          height: 100%;
          font-size: 1.05rem;
          z-index: 5;

          .box-content{
            display: inline-block;
            width: 100%;
            color: #ffffff;
            text-align: center;
            font-weight: 500;

            ._btn{
              color: #333333;
              font-weight: 500;
              font-size: 0.85rem;
              background-color: #ffffff;
            }

            .networks{
              a{
                @include flex-center-xy;
                margin: 3px 4px;
                width: 34px;
                height: 34px;
                color: #ffffff;
                border-radius: 50%;
                background-color: $purple;
              }
            }

            .name{
              margin-bottom: 12px;
              font-size: 1.50rem;
              font-weight: 700;
            }

            .cost{
              font-size: 1.3rem;
              font-weight: 700;
            }
          }
        }
      }
    }

    @media screen and (max-width: 575px){
      .col-hotel{
        .box{
          height: 490px;

          .inside{
            font-size: 0.95rem;

            .box-content{
            }
          }
        }
      }
    }
  }

}
