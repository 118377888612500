.modal-services-s1{
  .modal-content{
    border: 0;
  }

  .modal-header{
    padding: 0.4rem 1rem;
    color: #ffffff;
    background-color: $purple;

    .modal-title{
      font-size: 1.15rem;
      font-weight: 600;
    }

    .close{
      color: #ffffff;
      text-shadow: none;
      opacity: 1;
    }
  }

  .modal-body{
    position: relative;
    padding: 1.5rem 1rem;
    text-align: center;
    overflow: hidden;

    .subtitle{
      margin-bottom: 3px;
      color: $purple;
      font-size: 1.05rem;
      font-weight: 700;
      text-transform: uppercase;
    }

    .tel-title{
      .tel{
        font-size: 105%;
      }
    }

  }
}
