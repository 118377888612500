#footer{
  position: relative;
  width: 100%;
  padding: 15px 0 15px 0;
  color: #5a5c63;
  box-shadow: 0 -1px 6px rgba(0, 0, 0, 0.2);

  a{
    color: #5a5c63;

    &:hover{
      opacity: 0.85;
    }
  }

  .col-footer{
    margin-top: 7px;
    margin-bottom: 7px;
    font-size: 0.85rem;
    font-weight: 400;

    &:last-child{
      margin-bottom: 0;
    }

    .title{
      margin-bottom: 8px;
      color: #3e3e3e;
      font-size: 1.1rem;
      font-weight: 500;
    }
  }

  .col-logo{
    -ms-flex-item-align: center !important;
    align-self: center !important;

    img{
      width: 250px;
      max-width: 90%;
    }
  }

  .col-networks{
    line-height: 1.4;

    @media screen and (min-width: 992px){
      flex: 0 0 70px;
      max-width: 70px;
    }

    .link-net{
      display: block;
      color: #5a5c63;
      font-size: 1.6rem;
    }
  }

  @media screen and (max-width: 991px){
    .col-logo{
      text-align: center;
    }
    .col-networks{
      text-align: center;

      .link-net{
        display: inline-block;
        margin: 2px 5px;
      }
    }

    .col-footer{
      text-align: center;
    }
  }

}
